import _get from "lodash.get";
import api from "../api";

class Auth {

  get user () {
    return JSON.parse(localStorage.getItem("user"));
  }

  get userId () {
    return _get(this.user, "id");
  }

  get loggedIn () {
    return !!this.userId;
  }

  async registerAndLogin (data) {
    await api.auth.registration(data);
    await this.login(data);
  }

  async login (data) {
    const { token, user } = await api.auth.login(data);
    localStorage.setItem("user_token", token);
    localStorage.setItem("user", JSON.stringify(user));
    api.setToken(token);
  }

  logout () {
    localStorage.removeItem("user_token");
    localStorage.removeItem("user");
    api.deleteToken();
  }

}

// instantiate only in browser context
let auth;
if (!(typeof window === "undefined")) {
  auth = new Auth();
}

export default auth;
