import auth from "../auth";
const INTERCOM_ID = process.env.GATSBY_INTERCOM_ID;
export default class Intercom {

  static log () {
    console.log("%cIntercom", "color:#fff;background:#216DF4;padding:3px 5px;border-radius:4px", ...arguments);
  }

  static init = () => {
      if(auth.loggedIn){
        window.intercomSettings = {
            app_id: INTERCOM_ID,
            name: auth.user.name,
            email: auth.user.email,
            user_id: auth.user.id,
            custom_launcher_selector:'.my_custom_intercom'
        };
      }else{
        window.intercomSettings = {
            app_id: INTERCOM_ID,
            custom_launcher_selector:'.my_custom_intercom'
        };
    }
    if(typeof window.Intercom==="function"){
        window.Intercom('reattach_activator');
        window.Intercom('update',window.intercomSettings);
        this.log("Intercom has","enabled");
    }else{
        var i=function(){i.c(arguments);};
        i.q=[];
        i.c=function(args){i.q.push(args);};
        window.Intercom=i;
        var l=function(){var s=document.createElement('script');
        s.type='text/javascript';
        s.async=true;
        s.src='https://widget.intercom.io/widget/'+INTERCOM_ID;
        var x=document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s,x);};
        if(window.attachEvent){
            window.attachEvent('onload',l);
        }else{
            window.addEventListener('load',l,false);
        }}
        this.log("Intercom",INTERCOM_ID);
  };
}
