import ApiModule from "./api-module";

export default class OrdersApi extends ApiModule {
  async getOrders () {
    const response = await this.axios.get("/orders");
    return response.data;
  }

  async createOrder (data) {
    const response = await this.axios.post("/orders", data);
    return response.data;
  }
  async createGuestOrder (data) {
    const response = await this.axios.post("/guest-orders", data);
    return response.data;
  }

  // todo delete api method after production successfully test period
  // async processOrderPayment (orderId, data) {
  //   const response = await this.axios.post(`/orders/${orderId}/process-payment`, data);
  //   return response.data;
  // }
}
