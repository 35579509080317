import React, { Component } from "react";
import favicon from "../images/logomark.png";
import Helmet from "react-helmet";
import "../scss/layout.css";
import "../scss/all.scss";
import "lazysizes";
import Rejoiner from "../lib/tracking/rejoiner";
import FacebookPixel from "../lib/tracking/facebook-pixel";
import Justuno from "../lib/tracking/justuno";
import Hotjar from "../lib/tracking/hotjar";
import Intercom from "../lib/tracking/intercom";
import Adroll from "../lib/tracking/adroll";
import { readLocalCart } from "../lib/cart";
import Klayvio from "../lib/tracking/klayvio";

const REJOINER_SITE_ID = process.env.GATSBY_REJOINER_SITE_ID;
const REJOINER_DOMAIN = process.env.GATSBY_REJOINER_DOMAIN;
const FACEBOOK_PIXEL_ID = process.env.GATSBY_FACEBOOK_PIXEL_ID;

export default class GlobalHelmet extends Component {

  render () {
    return (
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png"/>
        <link rel="manifest" href="/favicon/site.webmanifest"/>
        <script src="https://kit.fontawesome.com/0e03334e62.js" crossorigin="anonymous"></script>
        <script type="text/javascript" helmetKey="rejoiner-init">{`
        var _rejoiner = _rejoiner || [];
        _rejoiner.push(['setAccount', '${REJOINER_SITE_ID}']);
        _rejoiner.push(['setDomain', '${REJOINER_DOMAIN}']);
      `}</script>
      </Helmet>
    );
  }

  loadRejoinerJs = () => {
    return new Promise(resolve => {

      // check already exists
      const src = "//cdn.rejoiner.com/js/v4/rj2.lib.js";
      const exists = document.querySelector(`script[src="${src}"]`);
      if (exists) return resolve({ asNewLoaded: false });

      const s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = src;
      s.onload = () => {
        resolve({ asNewLoaded: true });
      };
      const x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    });
  };

  loadFacebookPixelJs = () => {
    return new Promise(resolve => {
      if (window.fbq) return resolve({ asNewLoaded: false });

      const n = window.fbq = function() {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!window._fbq) window._fbq = n;
      n.push = n;
      n.loaded = true;
      n.version = "2.0";
      n.queue = [];

      const s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://connect.facebook.net/en_US/fbevents.js";
      s.onload = () => {
        resolve({ asNewLoaded: true });
      };

      const x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    });
  };

  componentDidMount () {
    this.loadRejoinerJs().then(({ asNewLoaded }) => {
      if (asNewLoaded) Rejoiner.log("rejoiner js loaded");
      /*
      if (!Rejoiner.firstCartDataSat) {
        Rejoiner.setCartData();
        readLocalCart().forEach(product => {
          Rejoiner.setCartItem(product);
        });
        Rejoiner.firstCartDataSat = true;
      }
      */
    });

    this.loadFacebookPixelJs().then(({ asNewLoaded }) => {
      if (asNewLoaded) {
        FacebookPixel.log("facebook pixel js loaded");
        FacebookPixel.init(FACEBOOK_PIXEL_ID);
      }
      FacebookPixel.track("PageView"); // this runs on every page where is global-helmet.js used
    });
    Klayvio.init();
    Justuno.init();
    Hotjar.init();
    Intercom.init();
    Adroll.init();
    if (typeof window.ga === "function") {
      window.ga('require', 'ec');
    }
  }
}
