const isProduction = process.env.NODE_ENV === "production";

export default class FacebookPixel {

  static log () {
    console.log("%cFacebook pixel", "color:#fff;background:#365899;padding:3px 5px;border-radius:4px", ...arguments);
  }

  static init (FACEBOOK_PIXEL_ID) {

    let comment;

    if (isProduction) {
      comment = "all events logs and go to facebook";
    } else {
      window.fbq = () => {}; // disable real tracking on production
      comment = "all events only logs, but don't go to facebook";
    }

    if (comment) {
      this.log("init", { pixel_id: FACEBOOK_PIXEL_ID, mode: process.env.NODE_ENV, comment });
    } else {
      this.log("init", { pixel_id: FACEBOOK_PIXEL_ID, mode: process.env.NODE_ENV });
    }

    fbq("init", `${FACEBOOK_PIXEL_ID}`);
  }

  static track (eventName, eventData = null) {
    if (eventData) {
      this.log("track", eventName, eventData);
      fbq("track", eventName, eventData);
    } else {
      this.log("track", eventName);
      fbq("track", eventName);
    }
  }

}
