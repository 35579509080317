import { getCartQuantity, getCartTotal } from "../cart";
import { readLocalCouponCode } from "../coupon";
import _get from "lodash.get";

// rejoiner flow https://bit.ly/2uN3K0T

export default class Rejoiner {

  static firstCartDataSat = false;

  static log () {
    console.log("%cRejoiner", "color:#fff;background:#3270e0;padding: 3px 5px;border-radius: 4px", ...arguments);
  }

  static subscribeToList (data) {
    this.log("subscribeToList", data);
    _rejoiner.push(["subscribeToList", data]);
  }

  static setCartData () {
    const cart_value = getCartTotal() * 100; // in cents
    const cart_item_count = getCartQuantity();
    const promo = readLocalCouponCode();
    const rejoinerCartData = { cart_value, cart_item_count };
    if (promo) rejoinerCartData.promo = promo;
    this.log("setCartData", rejoinerCartData);
    _rejoiner.push(["setCartData", rejoinerCartData]);
  }

  static setCartItem (product) {
    const rejoinerCartItem = {
      product_id: product.wordpress_id,
      name: product.name,
      price: product.price * 100,
      description: product.description,
      item_qty: product.quantity,
      product_url: product.url,
      category: (product.categories || []).map(c => c.name),
      image_url: _get(product, "images[1].src"),
      subscription: product.type === "subscription"
    };
    this.log("setCartItem", rejoinerCartItem);
    _rejoiner.push(["setCartItem", rejoinerCartItem]);
  }

  static removeCartItem (data) {
    this.log("removeCartItem", data);
    _rejoiner.push(["removeCartItem", data]);
  }

  static sendConversion (data) {
    this.log("sendConversion", data);
    _rejoiner.push(["sendConversion", data]);
  }
}
