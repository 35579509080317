import ApiModule from "./api-module";

export default class AccountApi extends ApiModule {

  async getMyAccount () {
    const response = await this.axios.get("/accounts/me");
    return response.data;
  }

  async updateShipping (data) {
    const response = await this.axios.put("/shipping", data);
    return response.data;
  }

  async updateBilling (data) {
    const response = await this.axios.put("/billing", data);
    return response.data;
  }

  async saveCard (data) {
    const response = await this.axios.post("/card", data);
    return response.data;
  }
}

