import Rejoiner from "./tracking/rejoiner";
import Klayvio from "./tracking/klayvio";

const LOCAL_CART_KEY = "smile_localCart";

export function readLocalCart () {
  if (typeof (Storage) !== "undefined") {
    if (localStorage.getItem(LOCAL_CART_KEY) === null) {
      localStorage.setItem(LOCAL_CART_KEY, JSON.stringify([]));
    }
    return JSON.parse(localStorage.getItem(LOCAL_CART_KEY));
  }
  return [];
}

export function saveLocalCart (localCart) {
  localStorage.setItem(LOCAL_CART_KEY, JSON.stringify(localCart));
}

export function clearLocalCart () {
  return saveLocalCart([]);
}

export const cartQuantityReducer = (quantity, product) => quantity + product.quantity;
export const cartTotalReducer = (total, product) => total + product.price * product.quantity;

export function getCartQuantity () {
  return readLocalCart().reduce(cartQuantityReducer, 0);
}

export function getCartTotal () {
  return readLocalCart().reduce(cartTotalReducer, 0);
}

export function getCartTotalOnlySubs () {
  return readLocalCart().filter(p => p.type === "subscription").reduce(cartTotalReducer, 0);
}

const headerCartAnimationTimers = {};

function playHeaderCartAnimation (newCartQuantity) {

  const duration = 1000; // css animation duration see in header-cart.scss

  const headerCartEls = Array.from(document.getElementsByClassName("header-cart")); // 2 elements
  headerCartEls.forEach((cartEl, index) => {

    // update count
    const countEl = cartEl.querySelector(".header-cart-count");
    if (countEl) countEl.textContent = newCartQuantity;

    // play animation
    clearTimeout(headerCartAnimationTimers[index]);
    const circleEl = cartEl.querySelector(".added-circle");
    if (circleEl) {
      circleEl.classList.add("animate");
      headerCartAnimationTimers[index] = setTimeout(() => {
        circleEl.classList.remove("animate");
      }, duration);
    }
  });
}

export function addToCart (localCart, product, { product_id, quantity }) {

  // check exists
  const localCartProduct = localCart.find(p => p.id === product_id);
  if (localCartProduct) {
    // increase existing product
    localCartProduct.quantity += quantity;
  } else {
    // push new product
    localCart.push({ ...product, quantity });
  }

  const newCartQuantity = localCart.reduce(cartQuantityReducer, 0);
  playHeaderCartAnimation(newCartQuantity);

  saveLocalCart(localCart);

  const cartAddedProduct = localCart.find(p => p.id === product_id);
  /*
  if (cartAddedProduct) {
    Rejoiner.setCartItem(cartAddedProduct);
    Rejoiner.setCartData();
  }
  */
  Klayvio.addToCart(product, localCart);
}

function updateHeaderCartCount (localCart) {
  const countEls = Array.from(document.getElementsByClassName("header-cart-count"));
  if (countEls) {
    const newCartQuantity = localCart.reduce(cartQuantityReducer, 0);
    countEls.forEach(countEl => countEl.textContent = newCartQuantity);
  }
}

export function cartAmountToggle (sign, product) {
  const localCart = readLocalCart();
  const localCartProduct = localCart.find(p => p.id === product.id);

  // calc new amount
  let newAmount = sign === "+" ? localCartProduct.quantity + 1 : localCartProduct.quantity - 1;
  if (newAmount < 0) newAmount = 0;

  localCartProduct.quantity = newAmount;

  saveLocalCart(localCart);
  updateHeaderCartCount(localCart);
  /*
  Rejoiner.setCartItem(localCartProduct);
  Rejoiner.setCartData();
  */
  return localCart;
}

export function removeFromCart (productId) {
  const localCart = readLocalCart();

  const index = localCart.findIndex(p => p.id === productId);
  if (~index) {
    const deleteProduct = localCart[index];

    localCart.splice(index, 1);
    saveLocalCart(localCart);
    updateHeaderCartCount(localCart);
    /*
    Rejoiner.removeCartItem({ product_id: deleteProduct.wordpress_id });
    Rejoiner.setCartData();
    */
  }

  return localCart;
}
