import axios from "axios";

import CouponsApi from "./coupons-api";
import OrdersApi from "./orders-api";
import AuthApi from "./auth-api";
import SubscriptionsApi from "./subscriptions-api";
import AccountApi from "./account-api";
import ContactFormsApi from "./contact-forms-api";
import UpsellApi from "./upsell-api";

class Api {
  constructor () {

    const commonHeaders = {};

    const token = localStorage.getItem("user_token");
    if (token) {
      commonHeaders.Authorization = `Bearer ${token}`;
    }

    this.axios = axios.create({
      baseURL: '/api/v1/',
      headers: {
        common: commonHeaders
      }
    });

    this.auth = new AuthApi(this.axios);
    this.account = new AccountApi(this.axios);
    this.coupons = new CouponsApi(this.axios);
    this.orders = new OrdersApi(this.axios);
    this.subscriptions = new SubscriptionsApi(this.axios);
    this.contactForms = new ContactFormsApi(this.axios);
    this.upsell = new UpsellApi(this.axios);
  }

  setToken (token) {
    this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  deleteToken () {
    delete this.axios.defaults.headers.common.Authorization;
  }

}

// instantiate only in browser context
let api;
if (!(typeof window === "undefined")) {
  api = new Api();
}

export default api;
