import { applyCouponToCartProducts } from "../../lib/coupon";
import _get from "lodash.get";


const KLAVIYO_ID = process.env.GATSBY_KLAVIYO_ID;
export default class Klayvio {

  static init = () => {

        const has = document.querySelector("script[src*=\"static.klaviyo.com/onsite/js/klaviyo.js\"]");
        if (!has) {
          const script = document.createElement("script");
          script.async = true;
          script.src = "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id="+KLAVIYO_ID;
          document.getElementsByTagName("head")[0].appendChild(script);
        }

  };  
  static identify(i) {
    //console.log("identify", i);
    //var _learnq = _learnq || [];
    _learnq.push(['identify', i]);
  }
  static viewProduct (product) {
    const item = {
        "ProductName": product.name,
        "ProductID": product.id,
        "Categories": product.categories.map(function(elem){
          return elem.name;
        }),
        "ImageURL": _get(product, "images[0].src"),
        "URL": `https://reasontosmile.com/products/${product.slug}`,
        "Price": product.price
      };
      _learnq.push(["track", "Viewed Product", item]);
  }

  static addToCart (product, localCart) {
    const result = applyCouponToCartProducts(localCart, null);
    _learnq.push(["track", "Added to Cart", {
        "$value": result.total,
        "AddedItemProductName": product.name,
        "AddedItemProductID": product.id,
        "AddedItemSKU": product.sku,
        "AddedItemCategories": product.categories.map(function(elem){
            return elem.name;
          }),
        "AddedItemImageURL": _get(product, "images[0].src"),
        "AddedItemURL": `https://reasontosmile.com/products/${product.p_slug}`,
        "AddedItemPrice": product.price,
        "AddedItemQuantity": 1,
        "ItemNames": localCart.map(function(cart){
            return cart.name;
        }),
        "CheckoutURL": "https://reasontosmile.com/checkout",
        "Items": localCart.map(function(cart){
            return {"ProductID": cart.id,
                    "SKU": cart.sku,
                    "ProductName": cart.name,
                    "Quantity": 1,
                    "ItemPrice": cart.price,
                    "RowTotal": cart.price,
                    "ProductURL": `https://reasontosmile.com/products/${cart.p_slug}`,
                    "ImageURL":  _get(cart, "images[0].src"),
                    "ProductCategories": cart.categories.map(function(elem){
                        return elem.name;
                      })
                   }
        })
      }]);
  }

  static startedCheckout (localCart, coupon) {
    const now = Date.now();
    const result = applyCouponToCartProducts(localCart, coupon);
    _learnq.push(["track", "Started Checkout", {
        "$event_id": now.toString(),
        "$value": result.total,
        "ItemNames": localCart.map(function(cart){
            return cart.name;
        }),
        "CheckoutURL": "https://reasontosmile.com/checkout",
        "Items": localCart.map(function(cart){
            return {"ProductID": cart.id,
                    "SKU": cart.sku,
                    "ProductName": cart.name,
                    "Quantity": cart.quantity,
                    "ItemPrice": cart.price,
                    "RowTotal": cart.price,
                    "ProductURL": `https://reasontosmile.com/products/${cart.p_slug}`,
                    "ImageURL":  _get(cart, "images[0].src"),
                    "ProductCategories": cart.categories.map(function(elem){
                        return elem.name;
                      })
                   }
        })
      }]);
  }
  
}
