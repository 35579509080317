import ApiModule from "./api-module";

export default class AuthApi extends ApiModule {

  async registration (data) {
    const response = await this.axios.post("/registration", data);
    return response.data;
  }

  async login (data) {
    const response = await this.axios.post("/login", data);
    return response.data;
  }

  async lostpassword (data) {
    const response = await this.axios.post("/lostpassword", data);
    return response.data;
  }

  async resetpassword (data) {
    const response = await this.axios.post("/resetpassword", data);
    return response.data;
  }
}
