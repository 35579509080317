import ApiModule from "./api-module";

export default class SubscriptionsApi extends ApiModule {

  async getSubscriptions (params = {}) {
    const response = await this.axios.get("/subscriptions", { params });
    return response.data;
  }

  async deleteSubscription (id, data = {}) {
    const response = await this.axios.delete(`/subscriptions/${id}`, data);
    return response.data;
  }

  async updateSubscription (id, data = {}) {
    const response = await this.axios.put(`/subscriptions/${id}`, data);
    return response.data;
  }
}
