import ApiModule from "./api-module";

export default class UpsellApi extends ApiModule {
  async getFunnel (orderId) {
    const response = await this.axios.get(`/upsell-funnel/${orderId}`);
    return response.data;
  }
  async processOffer (data) {
    const response = await this.axios.post("/process-offer", data);
    return response.data;
  }
}
