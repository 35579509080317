const ADROLL_ADV_ID = process.env.GATSBY_ADROLL_ADV_ID;
const ADROLL_PIX_ID = process.env.GATSBY_ADROLL_PIX_ID;
export default class Adroll {

  static log () {
    console.log("%cAdroll", "color:#fff;background:#00AEEC;padding:3px 5px;border-radius:4px", ...arguments);
  }

  static init = () => {
    window.adroll_adv_id = ADROLL_ADV_ID;
    window.adroll_pix_id = ADROLL_PIX_ID;
    var _onload = function() {
        if (document.readyState
                && !/loaded|complete/.test(document.readyState)) {
            setTimeout(_onload, 10);
            return
        }
        if (!window.__adroll_loaded) {
            window.__adroll_loaded = true;
            setTimeout(_onload, 50);
            return
        }
        var scr = document.createElement("script");
        var host = (("https:" == document.location.protocol) ? "https://s.adroll.com"
                : "http://a.adroll.com");
        scr.setAttribute('async', 'true');
        scr.type = "text/javascript";
        scr.src = host + "/j/roundtrip.js";
        ((document.getElementsByTagName('head') || [ null ])[0] || document
                .getElementsByTagName('script')[0].parentNode)
                .appendChild(scr);
    };
    if (window.addEventListener) {
        window.addEventListener('load', _onload, false);
        this.log("Adroll appended");  
    } else {
        window.attachEvent('onload', _onload)
        this.log("Adroll appended");
    }
  };
}
