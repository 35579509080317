import React, { Component } from "react";

export default class TextField extends Component {

  constructor (props) {
    super(props);

    this.state = {
      focused: false
    };
  }

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  handleChange = e => {
    this.props.onChange && this.props.onChange(e);
  };

  render () {
    const { label, type = "text", name, minLength, value, required = true, error } = this.props;

    const fieldClass = ["field"];
    if (value) fieldClass.push("has-value");
    if (this.state.focused) fieldClass.push("focused");

    return (
      <div className={fieldClass.join(" ")}>
        {label && <label className="label floating-label">{label}</label>}
        <div className={`control${error ? " has-icons-right" : ""}`}>
          <input
            className={`input${error ? " is-danger" : ""}`}
            required={required}
            type={type} name={name}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            minLength={minLength}
            value={value} />
          {error &&
          <span className="icon is-small is-right"><i className="fas fa-info-circle has-text-danger" /></span>}
        </div>
        {error && <p className="help is-danger">{error}</p>}
      </div>
    );
  }

}
