import parseISO from "date-fns/parseISO";
import isPast from "date-fns/isPast";
import api from "../api";
import { cartTotalReducer } from "./cart";

const LOCAL_COUPON_CODE_KEY = "smile_coupon_code";

export function readLocalCouponCode () {
  return localStorage.getItem(LOCAL_COUPON_CODE_KEY);
}

export function saveLocalCouponCode (couponCode) {
  localStorage.setItem(LOCAL_COUPON_CODE_KEY, couponCode);
}

export function deleteLocalCouponCode () {
  localStorage.removeItem(LOCAL_COUPON_CODE_KEY);
}

export async function isValidCoupon (couponCode) {
  const coupon = await api.coupons.checkCoupon({ code: couponCode });

  // coupon not found
  if (!coupon) {
    return {
      valid: false,
      error: "Coupon not found",
      coupon: null
    };
  }

  // check coupon expired
  const date_expires = parseISO(coupon.date_expires);
  const is_past = isPast(date_expires);
  if (is_past) {
    return {
      valid: false,
      error: `Coupon expired at ${date_expires.toLocaleDateString()}`,
      coupon: null
    };
  }

  return {
    valid: true,
    error: null,
    coupon
  };
}

/**
 * Recalculate new total with discount.
 *
 * @param {Array} cartProducts
 * @param {Object|null} coupon
 */
export function applyCouponToCartProducts (cartProducts, coupon) {

  const subtotal = cartProducts.reduce(cartTotalReducer, 0);
  let discount_sum = 0, discount_percent = 0;
  let total = subtotal;

  if (coupon) {
    if (coupon.discount_type === "fixed_cart") {

      discount_sum = parseFloat(coupon.amount);
      total = subtotal - discount_sum;

    } else if (coupon.discount_type === "percent" || coupon.discount_type === "universal_recurring_percent") {

      discount_percent = parseFloat(coupon.amount);
      discount_sum = (subtotal / 100 * discount_percent);
      total = subtotal - discount_sum;

    } else if (coupon.discount_type === "recurring_percent") {

      discount_percent = parseFloat(coupon.amount);
      const subtotal_only_subs = cartProducts.filter(p => p.type === "subscription").reduce(cartTotalReducer, 0);
      discount_sum = (subtotal_only_subs / 100 * discount_percent);
      total = subtotal - discount_sum;

    } else {
      // other WooCommerce discount types
    }
  }

  return {
    subtotal, // before discount
    discount_sum,
    discount_percent,
    total // after discount
  };
}
