const JUSTUNO_NUM = "C52D4736-3895-491E-BB4F-BA7D36C26391"; // todo from env

export default class Justuno {
  static log () {
    console.log("%cJustuno", "color:#fff;background:#4ecd5f;padding:3px 5px;border-radius:4px", ...arguments);
  }

  static init = () => {
    // check exists
    const has = document.querySelector("script[src*=\"cdn.jst.ai/vck.js\"]");
    if (!has) {
      const el = document.createElement("script");
      el.setAttribute("data-cfasync", "false");
      el.innerText = `window.ju_num="${JUSTUNO_NUM}";window.asset_host='//cdn.jst.ai/';(function(i,s,o,g,r,a,m){i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script',asset_host+'vck.js','juapp');`;
      document.body.append(el);
      this.log("Justuno appended", { JUSTUNO_NUM });
    }
  };

  static juapp () {
    window.juapp = window.juapp || function() {(window.juapp.q = window.juapp.q || []).push(arguments);};
    juapp(...arguments);
    this.log(...arguments);
  };
}
